// filters
$.expr[':'].external = function(obj) {
  'use strict';
  try {
    if (obj.href) {
      return !obj.href.match(/^mailto:/) &&
            !obj.href.match(/^skype:/) && !obj.href.match(/^tel:/) &&  (obj.hostname != location.hostname);
    }
    return false;
  } catch (e) {
    return false;
  }
};
$.expr[':'].download = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return (obj.href.match(/\.(pdf|doc|docx|xls||xlsx|cvx|jpg|gif|mp3|swf|txt|ppt|zip|gz|dmg)$/) !==  null);
        }
        return false;
    } catch (e) {
        return false;
    }
};
$.expr[':'].image = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return (obj.href.match(/\.(jpg|png|jpeg|JPG|PNG)$/) !==  null);
        }
        return false;
    } catch (e) {
        return false;
    }
};

$(document).ready(function() {
    'use strict';
    // enllaços externs en finestra nova
    $('a:external, a[rel=external]').attr('target', '_blank').addClass('external').attr('rel', 'external');


    // evitem spam als correus
    $("a[href*='(ELIMINAR)']").each(function() {
        var t = $(this);
        var omg = t.attr('href');
        var omg2 = t.text();
        t.attr('href', omg.replace('(ELIMINAR)', ''));
        t.text(omg2.replace('(ELIMINAR)', ''));
    });

    $(document).on('change', '.fileThumbnail .deleteFile input', function(){
    	if(this.checked) {
            $(this).closest('.deleteFile').hide();
            $(this).closest('.fileThumbnail').find('a.popupImage').hide();
            $(this).closest('.fileThumbnail').find('img').hide();
        }
    });

    /*
     * Amagar o mostrar mòduls per a usuaris amb pack basic
     */
	function showUserModulePackSection()
	{
		if ($("#userForm select#rol").val() == 'Redactor - Pack basic') {
			$("#userForm #modules").fadeIn();
		} else {
			$("#userForm #modules").hide();
		}

	}

    if ($("#userForm select#rol").length) {

	    $("#userForm select#rol").on ('change', function(){
	    	showUserModulePackSection();
	    });

	    showUserModulePackSection();
    }


//	vex.defaultOptions.className = "vex-theme-default";
//	vex.defaultOptions.className = "vex-theme-flat-attack";
	vex.defaultOptions.className = "vex-theme-plain";
	$('body').bootstrapMaterialDesign();


	  $(function () {
	    $('.popup-modal').magnificPopup({
	      type: 'inline',
	      preloader: false,
	      removalDelay: 300,
	      mainClass: 'animate',
	      closeMarkup: '<button title="%title%" type="button" class="mfp-close"><i class="material-icons" aria-hidden="true">close</i></button>',
	    });
	    $(document).on('click', '.popup-modal-dismiss', function (e) {
	      e.preventDefault();
	      $.magnificPopup.close();
	    });
        $(document).on('click', '.mfp-close', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
	  });



    var clipboard = new ClipboardJS('.copyClipboardBtn');
    $('.message-clipboard').hide();
    clipboard.on('success', function(e){
        if ($(e.trigger).parent().find('.message-clipboard').length) {
            $(e.trigger).parent().find('.message-clipboard').first().fadeIn('fast').delay(2000).fadeOut('slow');
        }
    });


    $(document).on('submit', 'form.ajax', function(event){
        $('.info-panel').remove();
        $(this).append('<div class="info-panel"><img src="/media/img/ajax-loader.gif" alt="" ></div>');

//        var formData = $(this).serialize();
        var formData = new FormData($(this)[0]);

        var url = $(this).attr('action');
        // process the form
        $.ajax({
            type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url         : url, //'/media/php/doProcess.php', // the url where we want to POST
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
            encode      : true,
            processData: false,
            contentType: false
        })
            // using the done promise callback
            .done(function(data) {
                // log data to the console so we can see
//                console.log(data.form);

                // here we will handle errors and validation messages
                if ( ! data.success) {
                	if(data.form == 'integrate_email-newsletter') {
                        if (data.popupMessage != '') {
							$('#email_newsletter').hide();
                            $('#email_newsletter').html(data.popupMessage);
							$('#email_newsletter').fadeIn();
                        }
                        return ;
	                } else if(data.form == 'integrate_email-file') {

                        if (data.popupMessage != '') {
							$('#email_file').hide();
                            $('#email_file').html(data.popupMessage);
							$('#email_file').fadeIn();
                        }
                        return ;
	                }
                } else {
                	if (data.form == 'integrate_mailchimp') {
                        if (data.popupMessage != '') {
                            $('#sendMailchimp').html(data.popupMessage);
                        }
                        return ;
                	} else if(data.form == 'integrate_mailerlite') {
                        if (data.popupMessage != '') {
                            $('#sendMailerLite').html(data.popupMessage);
                        }
                        return ;
                    } else if(data.form == 'integrate_email-file') {
                        if (data.popupMessage != '') {
                            $('#email_file').html(data.popupMessage);
                        }
                        return ;
	                } else if(data.form == 'integrate_email-newsletter') {
                        if (data.popupMessage != '') {
							$('#email_newsletter').hide();
                            $('#email_newsletter').html(data.popupMessage);
							$('#email_newsletter').fadeIn();
                        }
                        return ;
	                } else if (data.form == 'sendcampaign_mailchimp') {
                        if (data.popupMessage != '') {
                        	$('#sendMailchimp').hide();
                    		$('#sendMailchimp').html(data.popupMessage);
                            $('#sendMailchimp').fadeIn();
                        }
                        return ;
                    } else if (data.form == 'sendcampaign_mailerlite') {
                        if (data.popupMessage != '') {
                        	$('#sendMailerLite').hide();
                    		$('#sendMailerLite').html(data.popupMessage);
                            $('#sendMailerLite').fadeIn();
                        }
                        return ;
                    }

                    // ALL GOOD! just show the success message!
                    $('.info-panel').html('<div class="form-success">' + data.message + '</div>');
                    //$('.' + data.form).append('<div class="info-panel"><div class="form-success">' + data.message + '</div></div>');

                    // usually after form submission, you'll want to redirect
                    if (data.redirect) {
                        window.location = data.redirect;
                    }

                }
            });

        // stop the form from submitting the normal way and refreshing the page
        event.preventDefault();
    });

    $('.btn-loading-text').on('click', function() {
        var $this = $(this);
        $(this).html($(this).data('loading-text'));
        $(this).addClass('disabled');
    });

    // Treure classes del BMD per fer els forms més accessibles
    $('.bmd-form-group').removeClass('bmd-form-group');
    $('.bmd-label-static').removeClass('bmd-label-static');


    // Estadístiques
    if ($('div.charts').length) {
    	$( "div.charts" ).load( "/statistics/", {data_inici: $( "div.charts" ).data('data_inici'), data_fi: $( "div.charts" ).data('data_fi'), idioma: $( "div.charts" ).data('idioma')}, function(){
    		$('div.charts .houdiniForm').each(function() {//per cada formulari
    		    'use strict';
    		    //validacio formulari
    		    $(this).validate({
    		    	errorElement: "small",
    				errorPlacement: function ( error, element ) {
    					// Add the `help-block` class to the error element
    					error.addClass( "form-text error form-control-feedback" );

    					if ( element.prop( "type" ) === "checkbox" ) {
    						error.insertAfter( element.parent( "label" ) );
    					} else {
    						error.insertAfter( element );
    					}
    				},
    				success: function ( label, element ) {
    				},
    				highlight: function ( element, errorClass, validClass ) {
    					$( element ).parents( ".form-group" ).addClass( "has-danger" ).removeClass( "has-success" );
    					$( element ).addClass( "form-control-danger" );
    				},
    				unhighlight: function ( element, errorClass, validClass ) {
    					$( element ).parents( ".form-group" ).removeClass( "has-danger" );
    					$( element ).removeClass( "form-control-danger" );
    				}
    		    });
    		});


    		//datapicker
    		if ($("#data_inici").length > 0 ) {
		        $("#data_inici").datepicker({
    		    	format: "dd/mm/yyyy",
    		        todayBtn: "linked",
    		        clearBtn: true,
    		        language: idioma,
    		        calendarWeeks: true,
    		        autoclose: true,
    		        todayHighlight: true,
    		        container: '.form-group.data_inici'
    		    });
    		}

    		if ($("#data_fi").length > 0 ) {
		        $("#data_fi").datepicker({
    		    	format: "dd/mm/yyyy",
    		        todayBtn: "linked",
    		        clearBtn: true,
    		        language: idioma,
    		        calendarWeeks: true,
    		        autoclose: true,
    		        todayHighlight: true,
    		        container: '.form-group.data_fi'
    		    });
    		}

    	});
    }


    // pexels
    $(document).on('click', '#pexels_search_open a', function(e){
    	e.preventDefault();
    	$("#pexels_search").fadeIn();
    	return false;
    });
    function searchOnPexels() {
    	var searchValue = $('#pexels_search input#pxkeyword').val();
    	var button = $('#pexels_search button');
    	button.addClass('btn-loading');
    	var searchValue = $('#pexels_search input#pxkeyword').val();
    	$('#pexels_search_results').fadeOut();
    	$('#pexels_search_results').append('<p id="pexels_search_results_number"></p>');
    	$('#pexels_search_results').append('<div id="pexels_search_results_gallery" class="grid grid--4 grid--simple"></div>');

		jQuery.getJSON( "/pexels/pexels.php", {
            keyword: searchValue
          },

          function( data ){
        	$('#pexels_search_results_number').html(data.photos.length  + ' fotos');
      		if( !data.photos.length ) {

                return;
      		}

      		$('#pexels_search_results_gallery').html('');
      		data.photos.forEach(x => {
      			$('#pexels_search_results_gallery').append('<div class="grid__item"><div class="item--image"><img src="'+x.src.original+'?auto=compress&cs=tinysrgb&fit=crop&h=450&w=810" alt="" /><div class="overlay"></div><div class="item--image-actions"><a href="'+x.src.original+'?auto=compress&cs=tinysrgb&fit=crop&h=450&w=810" class="btn select-image">Seleccionar</a></div></div></div>');
      		});

      		$('#pexels_search_results').fadeIn();
      		button.removeClass('btn-loading');
        });
    }
    $(document).on('keypress', '#pexels_search #pxkeyword',function(e) {

        if(e.which == 13) {
        	e.preventDefault();
        	searchOnPexels();
            return false;
        }

    });
    $(document).on('click', '#pexels_search button', function(e){
    	e.preventDefault();
    	searchOnPexels();


		return false;
    });

    $(document).on('click', '#pexels_search_results_gallery a.select-image', function(e){
    	e.preventDefault();
    	$("#pexels_search input.pexels_input").val($(this).attr('href'));
		$("#pexels .fileThumbnail").html('<div><img src="' + $(this).attr('href') + '" alt=""/></div>')
		$("#pexels .form-control-file").hide();
    	$("#pexels_search").fadeOut();
    	return false;
    });


        // pexels
    $(document).on('click', '#pexels_search_open_xarxes a', function(e){
        e.preventDefault();
        $("#pexels_search_xarxes").fadeIn();
        return false;
    });
    function searchOnPexelsXarxes() {
        var searchValue = $('#pexels_search_xarxes input#pxkeyword').val();
        var button = $('#pexels_search_xarxes button');
        button.addClass('btn-loading');
        var searchValue = $('#pexels_search_xarxes input#pxkeyword').val();
        $('#pexels_search_results_xarxes').fadeOut();
        $('#pexels_search_results_xarxes').append('<p id="pexels_search_results_number_xarxes"></p>');
        $('#pexels_search_results_xarxes').append('<div id="pexels_search_results_gallery_xarxes" class="grid grid--4 grid--simple"></div>');

        jQuery.getJSON( "/pexels/pexels.php", {
            keyword: searchValue
            },

            function( data ){
            $('#pexels_search_results_number_xarxes').html(data.photos.length  + ' fotos');
                if( !data.photos.length ) {

                return;
                }

                $('#pexels_search_results_gallery_xarxes').html('');
                data.photos.forEach(x => {
                    $('#pexels_search_results_gallery_xarxes').append('<div class="grid__item"><div class="item--image"><img src="'+x.src.original+'?auto=compress&cs=tinysrgb&fit=crop&h=1080&w=1080" alt="" /><div class="overlay"></div><div class="item--image-actions"><a href="'+x.src.original+'?auto=compress&cs=tinysrgb&fit=crop&h=1080&w=1080" class="btn select-image">Seleccionar</a></div></div></div>');
                });

                $('#pexels_search_results_xarxes').fadeIn();
                button.removeClass('btn-loading');
        });
    }
    $(document).on('keypress', '#pexels_search_xarxes #pxkeyword',function(e) {

        if(e.which == 13) {
            e.preventDefault();
            searchOnPexelsXarxes();
            return false;
        }

    });
    $(document).on('click', '#pexels_search_xarxes button', function(e){
        e.preventDefault();
        searchOnPexelsXarxes();


        return false;
    });

    $(document).on('click', '#pexels_search_results_gallery_xarxes a.select-image', function(e){
        e.preventDefault();
        $("#pexels_search_xarxes input.pexels_input").val($(this).attr('href'));
        // $("#pexels_xarxes .fileThumbnail").html('<div><img src="' + $(this).attr('href') + '" alt=""/></div>')
        // $("#pexels_xarxes .form-control-file").hide();
        // $("#pexels_search_xarxes").fadeOut();
        $("#edit-form-xarxes-submit").click();
        return false;
    });

});


if (feature.svg) {
    $("html").addClass("svg");
}
